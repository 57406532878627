/**
 * 
 * Checkbox input component
 * This component is a custom re-usable to be used in the forms
 * 
 * @returns Checkbox : PureComponent
 */

import "./Checkbox.scss";

// Defining our generic type
type ComponentProps = {
    isChecked : boolean,
    label : string,
    handleChange : () => void
}

const Checkbox = ({isChecked, label, handleChange} : ComponentProps) => {

    const onClickHandler = () => {

        console.log("Clicked");
        handleChange();
    };

    return(
        <div className="checkbox">
            <label className="checkbox__label" id="checkbox-label" htmlFor="checkbox" onClick={onClickHandler}>{ label }
                <input
                    type="checkbox"
                    name="checkbox"
                    checked={isChecked}
                    onChange={() => {
                        console.log("andlee");
                    }}
                    className="checkbox__input"
                />
                <span className="tickbox"></span>
            </label>
        </div>
    );
};

export default Checkbox;