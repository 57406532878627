/**
 * Hair Instructions page
 * 
 * @returns HairInstructionsPage : PureComponent
 */

 import './HairInstructionsPage.scss';
 import takeBlueLogo from "../assets/take-blue.png";
 import storeBlueLogo from "../assets/store-blue.png";
 import printBlueLogo from "../assets/print-blue.png";
 import resultsBlueLogo from "../assets/results-blue.png";
 import './HairThankYouPage.scss';
 import { NavLink } from "react-router-dom";
 import { BsArrowLeftSquareFill } from "react-icons/bs";
 
 const HairInstructionsPage = () => {
 
     return(
         <section>
             <NavLink to="/" className="blood-form-link"><BsArrowLeftSquareFill/>Go Back</NavLink>
             <section className="home-page">
             
             <h1 className="home-page__header" style={{ fontSize: "1.6rem" }}><b>Hair Instructions</b></h1>
             <div className="thank-you">
 
             <div className="thank-you__subtitle">
                 <p>The following four steps are on the form for you to print and submit with your sample. Please read each step carefully. When you’re ready to take your sample, please ensure you have the following things ready: your sample collection kit. scissors, and a pen.</p>
             </div>
 
             <ul className="thank-you__steps">
 
                 <li className="thank-you__step">
 
                     <img
                         src={takeBlueLogo}
                         alt="take your test blue logo"
                         width="60"
                         height="60"
                     />
 
                     <div>
                         <h3>Step 1 | Taking Your Sample</h3>
                         <p>Using your forefinger and thumb gather 3 or 4 hairs and slightly twist the hair together. This will make cutting the hair sample much easier. Try to cut the hair sample as close to the scalp as possible, using one cut. We do not need the follicle (root) for the testing, but this can be included if easier.</p>
                         <br/>
                         <p><b>Guidance</b> | The sample does not need to be from your head; we can test any body hair. Hair colouring, bleaching, or other alternations and treatments will not affect the bio-resonance testing process.</p>
                     </div>
                 </li>
 
                 <li className="thank-you__step">
                     <img
                         src={storeBlueLogo}
                         alt="store your test blue logo"
                         width="60"
                         height="60"
                     />
                     <div>
                         <h3>Step 2 | Storing Your Sample</h3>
                         <p>Please seal the hair sample in the clear bag provided to ensure it stays dry and secure during transit. Ensure your sample is secure in the bag provided and your sample identifier number has been attached to the bag.</p>
                         <br/>
                         <p><b>Guidance</b> | We cannot test samples that are loose, or in metal foil or metallic packaging. Please be careful not to include other items in the same bag as your sample as this can affect testing. Each person submitting samples will have a code, so ensure that each person has their code on their sample.</p>
                     </div>
                 </li>
 
                 <li className="thank-you__step">
                     <img
                         src={printBlueLogo}
                         alt="print your test blue logo"
                         width="60"
                         height="60"
                     />
                     <div>
                         <h3>Step 3 | Sending Your Sample</h3>
                         <p>Please fill in your Submission Card clearly labeling your Order Number, Order Date, Order Name, Name of Hair Sample, email address and your Contact Telephone number.</p>
                         <br/>
                         <p><b>Guidance</b> | Place your hair sample and submission card in the secure envolope and mail this back to our lab. Please ensure the correct postage is paid; incorrect postage can cause major delays to the processing. <b>Healthy Stuff</b> are not liable to pay additional fees, your sample may be returned.</p>
                     </div>
                 </li>
 
                 <li className="thank-you__step">
                     <img
                         src={resultsBlueLogo}
                         alt="get your results blue logo"
                         width="60"
                         height="60"
                     />
                     <div>
                         <h3>Step 4 | What happens next?</h3>
                         <p>Once you have mailed your sample, it willl be be delivered to the local postal hub in your country before being priority shipped to our testing facility in the UK.
                         Please note, while most samples arrive with our UK lab within 8 business days or less, during times of significant postal delays, this may take up to 21 business days. However, this is very rare.
                         When your sample arrives at our lab, you will receive an email confirming receipt. Our lab technician team will then process your sample, and your test results will be sent via email, to the email address you provided when activating your test.</p>
                         <br/>
                         <p><b>Guidance</b> | It is important to note that bioresonance tests do not make a medical diagnosis nor are they intended to be a substitute for professional medical advice or treatment. If you are suffering from or suspect you have a medical condition you should consult a qualified practitioner in the first instance.</p>
                     </div>
                 </li>    
             </ul>
                     <div className="hair-page__hair-instruction">
                         <NavLink to="/hair-submissions" className="instructions-hair-test-link">
                             <p>Activate Hair Test</p>
                         </NavLink>
                     </div>
                 </div>
 
             </section>
             </section>
         
     );
 };
 
 export default HairInstructionsPage;