/**
 * 
 * Home component
 * This component showcases a grid which routes visitors to different tests
 * The design is based off https://checkmybodyhealth.com/activate/
 * 
 * @returns Home : PureComponent
 */

 import "./AmazonHomePage.scss";
 import { NavLink } from "react-router-dom";
 import { BsFillDropletFill } from "react-icons/bs";
 import hair_strands from "../assets/hair_strands.png";
 import { BsArrowLeftSquareFill } from "react-icons/bs";
 
 const HomePage = () => {
 
     return(
         <section className="home-page">
            <NavLink to="/" className="hair-form-link" style={{ color: "#FFAE42" }}><BsArrowLeftSquareFill/>Go Back</NavLink>
            
             <h1 className="home-page__header" style={{ color: "#FFAE42" }}>Please choose your Amazon test</h1>
 
             <ul className="home-page__tests-grid">
 
                 <li className="home-page__test">
                     <NavLink to="/amazon/blood-submissions">
                         <BsFillDropletFill style={{ fill : "#ED0C6E" }}/>
                         <p style={{ color: "#ED0C6E" }}>Blood Test</p>
                     </NavLink>
                 </li>
 
                 
                 <li className="home-page__test">
                     <NavLink to="/amazon/hair-submissions">
                         <img
                             src={hair_strands}
                             alt="Hair strands logo"
                         />
                         <p style={{ color: "#54B9D9" }}>Hair Test</p>
                     </NavLink>
                 </li>                       
 
             </ul>
 
             <ul className="home-page__instructions-grid">
 
                 <li className="home-page__instructions-link">
                     <NavLink to="/blood">
                         How to use a blood kit
                     </NavLink>
                 </li>
 
                 
                 <li className="home-page__instructions-link">
                     <NavLink to="/hair">
                         How to use a hair kit
                     </NavLink>
                 </li>
                 
             </ul>
 
             <div className="home-page__embedded-video">
                 <iframe 
                     src="https://www.youtube.com/embed/p6box9jzlDw" 
                     title="Activatemytest.com | How it Works" 
                     frameBorder="0" 
                     allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                     allowFullScreen>
                 </iframe>
             </div>
 
         </section>
     );
 };
 
 export default HomePage;